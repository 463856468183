// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import { StreamActions } from "@hotwired/turbo"
import "@hotwired/turbo-rails"

let navigating = false

// <turbo-stream action="redirect"></turbo-stream>
StreamActions.redirect = function () {
  if(navigating) return

  navigating = true
  const urlParams = new URLSearchParams(window.location.search);

  if(urlParams.has("return_to")) {
    window.location.href = urlParams.get("return_to")
  }
}

// <turbo-stream action="refresh"></turbo-stream>
StreamActions.refresh = function () {
  if(navigating) return

  navigating = true
  window.location.reload()
}

Rails.start()
